import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class McademyItemService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/mcademy_items?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/mcademy_items?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getItem(id) {
    return axios.get(API_URL + `admin/mcademy_items/${id}`, {
      headers: authHeader(),
    });
  }

  createZoomItem(id) {
    return axios.get(API_URL + `admin/mcademy_items/zoom/create/${id}`, {
      headers: authHeader(),
    });
  }

  deleteZoomItem(id, zoom_id) {
    return axios.get(API_URL + `admin/mcademy_items/zoom/delete/${id}/${zoom_id}`, {
      headers: authHeader(),
    });
  }

  save(c, tags) {
    return axios.put(API_URL + `admin/mcademy_items/${c.id}`, { ...c, tags: tags }, { headers: authHeader() });
  }

  async create(data) {
    const newsItem = await axios.post(
      API_URL + `admin/mcademy_items`,
      { ...data },
      {
        headers: authHeader(),
      }
    );
    console.log(newsItem);
    return newsItem.data;
  }

  getMcademyItemTags() {
    return axios.get(API_URL + `admin/mcademy_tags`, {
      headers: authHeader(),
    });
  }

  getBusinessUnits() {
    return axios.get(API_URL + `admin/business_units`, {
      headers: authHeader(),
    });
  }

  getTrainers() {
    return axios.get(API_URL + `admin/mcademy_items/users`, {
      headers: authHeader(),
    });
  }

  getRegistrations(id) {
    return axios.get(API_URL + `admin/mcademy_items/${id}/registrations`, {
      headers: authHeader(),
    });
  }

  getDownload(id) {
    return axios({
      method: 'get', 
      url: API_URL + `admin/mcademy_items/${id}/export`,
      responseType: 'arraybuffer', // or 'blob'
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/mcademy_items/${id}`, {
      headers: authHeader(),
    });
  }

  // --------------

  getProgramEvents(id) {
    return axios.get(API_URL + `admin/mcademy_item_program_events/${id}`, {
      headers: authHeader(),
    });
  }

  addProgramEvent(data) {
    return axios.post(API_URL + `admin/mcademy_item_program_events`, data, {
      headers: authHeader(),
    });
  }

  updateProgramEvent(data) {
    return axios.put(API_URL + `admin/mcademy_item_program_events/${data.id}`, data, {
      headers: authHeader(),
    });
  }

  deleteProgramEvent(id) {
    return axios.delete(API_URL + `admin/mcademy_item_program_events/${id}`, {
      headers: authHeader(),
    });
  }

}

export default new McademyItemService();
